<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>待审核订单</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content-container">
      <a-form :form="form" class="advanced-search-form" @submit="onSearch">
        <a-row class="row">
          <a-col :span="21">
            <a-row>
              <a-col :span="8">
                <a-form-item label="订单编号">
                  <a-input v-decorator="['orderSn']" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="订单类型">
                  <a-select v-decorator="['orderType']" allow-clear>
                    <a-select-option value="即时订单">
                      即时订单
                    </a-select-option>
                    <a-select-option value="远期订单">
                      远期订单
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="经销商">
                  <a-input v-decorator="['businessVenuName']" allow-clear />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-item label="是否全款">
                  <a-select
                    v-decorator="['paymentType', { initialValue: '-1' }]"
                  >
                    <a-select-option value="-1">全部</a-select-option>
                    <a-select-option value="0">否</a-select-option>
                    <a-select-option value="1">是</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="收货人电话">
                  <a-input
                    v-decorator="[
                      'tel',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]{0,11}$/,
                            message: '手机号格式不正确'
                          }
                        ]
                      }
                    ]"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="下单时间">
                  <a-range-picker
                    v-decorator="['time']"
                    allow-clear
                    @change="onChangeTime"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="3" class="col">
            <a-button
              type="primary"
              icon="search"
              class="search-button"
              @click="onSearch"
            >
              搜索
            </a-button>
            <a-button icon="undo" class="btn-reset" @click="onReset">
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <template slot="businessVenuName" slot-scope="text, record">
            <div>
              <div>{{ record.dealerName }}</div>
              <div class="dealer-type">
                {{ record.dealerType
                }}{{ record.storeType ? "(" + record.storeType + ")" : "" }}
              </div>
            </div>
          </template>
          <template slot="action" slot-scope="text, record">
            <a @click="onOrderDetail(record.orderSn)">查看详情</a>
          </template>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { fetchToExamineList } from "../../services/OrderService";
import { tsFormat } from "../../components/DateUtils";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn"
  },
  {
    title: "订单类型",
    dataIndex: "orderType"
  },
  {
    title: "经销商",
    dataIndex: "businessVenuName",
    scopedSlots: { customRender: "businessVenuName" }
  },
  {
    title: "支付方式",
    dataIndex: "paymentType"
  },
  {
    title: "收货人姓名",
    dataIndex: "consigneeName"
  },
  {
    title: "收货人电话",
    dataIndex: "consigneeTel"
  },
  {
    title: "下单时间",
    dataIndex: "createTime"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      form: this.$form.createForm(this),
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      startTime: "",
      endTime: ""
    };
  },
  mounted() {
    this.fetchToExamineOrderList();
  },
  methods: {
    // 获取列表信息
    fetchToExamineOrderList() {
      const params = {
        orderSn: this.form.getFieldsValue().orderSn,
        orderType: this.form.getFieldsValue().orderType,
        businessVenuName: this.form.getFieldsValue().businessVenuName,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        orderStatus: "待审核",
        startTime: this.startTime,
        endTime: this.endTime,
        paymentType: this.form.getFieldsValue().paymentType,
        consigneeTel: this.form.getFieldsValue().tel
      };
      fetchToExamineList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const records = resp.data.data.records;
          const data = resp.data.data;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
          if (records.length > 0) {
            records.forEach(item => {
              item.createTime = tsFormat(item.createTime);
            });
          }
          this.tblData = records;
        }
      });
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.fetchToExamineOrderList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchToExamineOrderList();
    },
    // 查看订单详情
    onOrderDetail(orderSn) {
      this.$router.push({
        name: "ToExamineDetail",
        params: { orderSn: orderSn }
      });
    },
    // 调整时间
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0];
        this.endTime = dateString[1];
      }
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.startTime = "";
      this.endTime = "";
      this.fetchToExamineOrderList();
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}
.row {
  display: flex;
}
.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.btn-reset {
  margin-bottom: 8px;
}
.dealer-type {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}
</style>
