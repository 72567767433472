var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('Breadcrumb',[_c('BreadcrumbItem',[_vm._v("待审核订单")])],1)],1),_c('div',{staticClass:"content-container"},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.form},on:{"submit":_vm.onSearch}},[_c('a-row',{staticClass:"row"},[_c('a-col',{attrs:{"span":21}},[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"订单编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['orderSn']),expression:"['orderSn']"}],attrs:{"allow-clear":""}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"订单类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['orderType']),expression:"['orderType']"}],attrs:{"allow-clear":""}},[_c('a-select-option',{attrs:{"value":"即时订单"}},[_vm._v(" 即时订单 ")]),_c('a-select-option',{attrs:{"value":"远期订单"}},[_vm._v(" 远期订单 ")])],1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"经销商"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['businessVenuName']),expression:"['businessVenuName']"}],attrs:{"allow-clear":""}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"是否全款"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['paymentType', { initialValue: '-1' }]),expression:"['paymentType', { initialValue: '-1' }]"}]},[_c('a-select-option',{attrs:{"value":"-1"}},[_vm._v("全部")]),_c('a-select-option',{attrs:{"value":"0"}},[_vm._v("否")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("是")])],1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"收货人电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'tel',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]{0,11}$/,
                          message: '手机号格式不正确'
                        }
                      ]
                    }
                  ]),expression:"[\n                    'tel',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]{0,11}$/,\n                          message: '手机号格式不正确'\n                        }\n                      ]\n                    }\n                  ]"}],attrs:{"allow-clear":""}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"下单时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['time']),expression:"['time']"}],attrs:{"allow-clear":""},on:{"change":_vm.onChangeTime}})],1)],1)],1)],1),_c('a-col',{staticClass:"col",attrs:{"span":3}},[_c('a-button',{staticClass:"search-button",attrs:{"type":"primary","icon":"search"},on:{"click":_vm.onSearch}},[_vm._v(" 搜索 ")]),_c('a-button',{staticClass:"btn-reset",attrs:{"icon":"undo"},on:{"click":_vm.onReset}},[_vm._v(" 重置 ")])],1)],1)],1),_c('div',{staticClass:"main-content"},[_c('a-table',{attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"pagination":_vm.pagination,"loading":_vm.loading,"row-key":function (record, index) {
            return index;
          }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"businessVenuName",fn:function(text, record){return [_c('div',[_c('div',[_vm._v(_vm._s(record.dealerName))]),_c('div',{staticClass:"dealer-type"},[_vm._v(" "+_vm._s(record.dealerType)+_vm._s(record.storeType ? "(" + record.storeType + ")" : "")+" ")])])]}},{key:"action",fn:function(text, record){return [_c('a',{on:{"click":function($event){return _vm.onOrderDetail(record.orderSn)}}},[_vm._v("查看详情")])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }